import { css, html, LitElement } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import '../../../app-link/app-link.js';
import '../../../../achievements/achievements-card/achievements-card.js';
import '../../../../achievements/achievements-table/achievements-table.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { LocalizeNova } from '../../../../../mixins/localize-nova/localize-nova.js';

class UserAchievements extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      user: { type: Object, attribute: false },
      _achievements: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      heading2Styles,
      css`
        :host {
          border: 1px solid #cdd5dc;
          border-radius: 6px;
          display: block;
          padding: 16px;
        }

        .achievements-grid {
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr 1fr;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.user = null;
    this._achievements = [];
  }

  updated(changedProperties) {
    if (changedProperties.has('user')) {
      this._achievements = this.user?.achievements || [];
    }
  }

  render() {
    return html`
      <h2 class="d2l-heading-2">Achievements</h2>
      <div class="achievements-grid">
        ${repeat(this._achievements, achievement => achievement.id, achievement => html`
          <achievements-card .achievement=${achievement}></achievements-card>
        `)}
      </div>
      <app-link d2l-link href="achievements-table">${this.localize('user-achievements.see-all')}</app-link>
    `;
  }
}

window.customElements.define('user-achievements', UserAchievements);
