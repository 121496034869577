import { css, html, LitElement } from 'lit';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../../../mixins/localize-nova/localize-nova.js';

class UserPoints extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      user: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return css`
      .level-title {
        font-size: 0.8rem;
        margin: 0;
        text-align: center;
      }
      .level-number {
        font-size: 2.5rem;
        margin-bottom: 15px;
        margin-top: 22px;
      }
      .level-name {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 0;
        margin-top: -5px;
      }
      .points-number {
        margin-bottom: 5px;
        margin-top: 0;
      }
      .progress-container {
        align-items: center;
        border-top: 1px solid #cccccc;
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 300px;
        padding-top: 15px;
      }

      /* progress bar */
      .progress-bar-container {
        background-color: #e0e0e0;
        border: 1px solid #000000;
        border-radius: 10px;
        height: 23px;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
      .progress-bar {
        align-items: center;
        background-color: #29a6ff;
        border-radius: 10px 0 0 10px;
        box-shadow: 0 0 10px #29a6ff;
        display: flex;
        height: 23px;
        justify-content: center;
        transition: width 1s;
        width: 0;
      }
      .progress-text {
        color: black;
        font-size: 9px;
        position: absolute;
        right: 10px;
        text-align: right;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
      .progress-bar-max-text {
        color: black;
        font-size: 9px;
        height: 10px;
        line-height: 10px;
        margin: 3px 0 0 0;
        padding: 0 5px 0 0;
        text-align: right;
        width: 100%;
      }
    `;
  }

  constructor() {
    super();
    this.user = null;
  }

  get userLevel() {
    if (!this.user) {
      return { name: 'no user Found', minPoints: 0, maxPoints: 0, levelNumber: 0 };
    }

    const points = this.user.points;
    const levels = [
      { name: 'Mini Droplet', min: 0, max: 33 },
      { name: 'Average Droplet', min: 34, max: 66 },
      { name: 'Large Droplet', min: 67, max: 100 },
      { name: 'Mini Ripple', min: 101, max: 133 },
      { name: 'Average Ripple', min: 134, max: 166 },
      { name: 'Large Ripple', min: 167, max: 200 },
      { name: 'Mini Stream', min: 201, max: 233 },
      { name: 'Average Stream', min: 234, max: 266 },
      { name: 'Large Stream', min: 267, max: 300 },
      { name: 'Mini Surge', min: 301, max: 333 },
      { name: 'Average Surge', min: 334, max: 366 },
      { name: 'Large Surge', min: 367, max: 400 },
      { name: 'Mini Wave', min: 401, max: 433 },
      { name: 'Average Wave', min: 434, max: 466 },
      { name: 'Large Wave', min: 467, max: 500 },
    ];

    for (let i = 0; i < levels.length; i++) {
      const level = levels[i];
      if (points >= level.min && points <= level.max) {
        const percentage = ((points - level.min) / (level.max - level.min)) * 100;
        const pointsToNextLevel = level.max - points;
        return { name: level.name, minPoints: level.min, maxPoints: level.max, levelNumber: i + 1, percentage, pointsToNextLevel, nextLevel: i + 2 };
      }
    }

    return { name: 'Level not defined', minPoints: 0, maxPoints: 0, levelNumber: 0 };
  }

  render() {
    const userLevel = this.userLevel;
    return html`
      <div class="progress-container">
        <p class="level-title">LVL</p>
        <p class="level-number">${userLevel.levelNumber}</p>
         <p class="points-number">${this.user?.points }XP</p>
        <div class="progress-bar-container">
          <div class="progress-bar" style="width: ${userLevel.percentage}%;">
          </div>
            <span class="progress-text">${userLevel.pointsToNextLevel}XP to LVL ${userLevel.nextLevel} </span>
        </div>
        <p class="progress-bar-max-text">33XP</span>
        <p class="level-name">${userLevel.name}</p>
      </div>
    `;
  }
}

window.customElements.define('user-points', UserPoints);

