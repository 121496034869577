export const testData = {
  'LegendaryExplorer':{
    'metadata': {
      'rankTitle': 'Legendary Explorer',
      'rankSize': 3,
      'experienceTop': 1000,
      'experienceBottom': 901,
    },
    'rankings': [
      {
        'rank': 1,
        'name': 'Tony Stark',
        'experience': 1000,
        'department': 'Genius Innovation',
      },
      {
        'rank': 2,
        'name': 'Hermione Granger',
        'experience': 999,
        'department': 'Magical Studies',
      },
      {
        'rank': 3,
        'name': 'Sherlock Holmes',
        'experience': 998,
        'department': 'Mystery Solvers',
      },
    ] },
  'Pioneer': {
    'metadata': {
      'rankTitle': 'Pioneer',
      'rankSize': 5,
      'experienceTop': 900,
      'experienceBottom': 801,
    },
    'rankings': [
      {
        'rank': 1,
        'name': 'Zendaya',
        'experience': 899,
        'department': 'Fashion & Design',
      },
      {
        'rank': 2,
        'name': 'Billie Eilish',
        'experience': 889,
        'department': 'Music & Performance',
      },
      {
        'rank': 3,
        'name': 'Harry Styles',
        'experience': 875,
        'department': 'Communications',
      },
      {
        'rank': 4,
        'name': 'Olivia Rodrigo',
        'experience': 860,
        'department': 'Social Media',
      },
      {
        'rank': 5,
        'name': 'Timothée Chalamet',
        'experience': 845,
        'department': 'Public Relations',
      },
    ],
  },
  'Voyager':{
    'metadata': {
      'rankTitle': 'Voyager',
      'rankSize': 7,
      'experienceTop': 800,
      'experienceBottom': 701,
    },
    'rankings': [
      {
        'rank': 1,
        'name': 'Darth Vader',
        'experience': 799,
        'department': 'Galactic Empire',
      },
      {
        'rank': 2,
        'name': 'Luke Skywalker',
        'experience': 785,
        'department': 'Force Sensitives',
      },
      {
        'rank': 3,
        'name': 'Leia Organa',
        'experience': 772,
        'department': 'Rebel Alliance',
      },
      {
        'rank': 4,
        'name': 'Han Solo',
        'experience': 760,
        'department': 'Smuggling & Trade',
      },
      {
        'rank': 5,
        'name': 'Obi-Wan Kenobi',
        'experience': 745,
        'department': 'Jedi Council',
      },
      {
        'rank': 6,
        'name': 'Yoda',
        'experience': 730,
        'department': 'Jedi Mastery',
      },
      {
        'rank': 7,
        'name': 'Chewbacca',
        'experience': 715,
        'department': 'Wookiee Operations',
      },
    ],
  },
  'Navigator': {
    'metadata': {
      'rankTitle': 'Navigator',
      'rankSize': 40,
      'experienceTop': 700,
      'experienceBottom': 601,
    },
    'rankings': [
      {
        'rank': 1,
        'name': 'Aragorn',
        'experience': 699,
        'department': 'Rangers & Leadership',
      },
      {
        'rank': 2,
        'name': 'Gandalf',
        'experience': 695,
        'department': 'Wizardry & Wisdom',
      },
      {
        'rank': 3,
        'name': 'Frodo Baggins',
        'experience': 688,
        'department': 'Ring-bearers',
      },
      {
        'rank': 4,
        'name': 'Legolas',
        'experience': 685,
        'department': 'Elven Archery',
      },
      {
        'rank': 5,
        'name': 'Gimli',
        'experience': 680,
        'department': 'Dwarven Defense',
      },
      {
        'rank': 6,
        'name': 'Boromir',
        'experience': 676,
        'department': 'Stewards of Gondor',
      },
      {
        'rank': 7,
        'name': 'Samwise Gamgee',
        'experience': 670,
        'department': 'Hobbit Valor',
      },
      {
        'rank': 8,
        'name': 'Arwen',
        'experience': 665,
        'department': 'Elven Diplomacy',
      },
      {
        'rank': 9,
        'name': 'Elrond',
        'experience': 660,
        'department': 'Elven Council',
      },
      {
        'rank': 10,
        'name': 'Galadriel',
        'experience': 658,
        'department': 'Wisdom & Leadership',
      },
      {
        'rank': 11,
        'name': 'Gollum',
        'experience': 654,
        'department': 'Ring-related Matters',
      },
      {
        'rank': 12,
        'name': 'Eomer',
        'experience': 650,
        'department': 'Rohan Cavalry',
      },
      {
        'rank': 13,
        'name': 'Faramir',
        'experience': 645,
        'department': 'Rangers of Ithilien',
      },
      {
        'rank': 14,
        'name': 'Merry Brandybuck',
        'experience': 640,
        'department': 'Hobbit Adventures',
      },
      {
        'rank': 15,
        'name': 'Pippin Took',
        'experience': 635,
        'department': 'Hobbit Reconnaissance',
      },
      {
        'rank': 16,
        'name': 'Theoden',
        'experience': 630,
        'department': 'Kingdom of Rohan',
      },
      {
        'rank': 17,
        'name': 'Treebeard',
        'experience': 625,
        'department': 'Ent Leadership',
      },
      {
        'rank': 18,
        'name': 'Saruman',
        'experience': 620,
        'department': 'White Council',
      },
      {
        'rank': 19,
        'name': 'Eowyn',
        'experience': 615,
        'department': 'Shieldmaidens of Rohan',
      },
      {
        'rank': 20,
        'name': 'Bilbo Baggins',
        'experience': 610,
        'department': 'Hobbit Histories',
      },
      {
        'rank': 21,
        'name': 'Sauron',
        'experience': 608,
        'department': 'Dark Lord Operations',
      },
      {
        'rank': 22,
        'name': 'Witch-king of Angmar',
        'experience': 606,
        'department': 'Nazgul Leadership',
      },
      {
        'rank': 23,
        'name': 'Radagast',
        'experience': 604,
        'department': 'Nature & Magic',
      },
      {
        'rank': 24,
        'name': 'Thorin Oakenshield',
        'experience': 602,
        'department': 'Dwarven Kingdoms',
      },
      {
        'rank': 25,
        'name': 'Balin',
        'experience': 600,
        'department': 'Dwarven Fellowship',
      },
      {
        'rank': 26,
        'name': 'Kili',
        'experience': 600,
        'department': 'Dwarven Battle Strategies',
      },
      {
        'rank': 27,
        'name': 'Fili',
        'experience': 600,
        'department': 'Dwarven Tactics',
      },
      {
        'rank': 28,
        'name': 'Gothmog',
        'experience': 600,
        'department': 'Orc Command',
      },
      {
        'rank': 29,
        'name': 'Sharku',
        'experience': 600,
        'department': 'Uruk-hai Operations',
      },
      {
        'rank': 30,
        'name': 'Grima Wormtongue',
        'experience': 600,
        'department': 'Political Manipulation',
      },
      {
        'rank': 31,
        'name': 'Shelob',
        'experience': 600,
        'department': 'Arachnid Affairs',
      },
      {
        'rank': 32,
        'name': 'The Mouth of Sauron',
        'experience': 600,
        'department': 'Dark Propaganda',
      },
      {
        'rank': 33,
        'name': 'Galandriel\'s Mirror',
        'experience': 600,
        'department': 'Prophecy and Visions',
      },
      {
        'rank': 34,
        'name': 'Denethor',
        'experience': 600,
        'department': 'Stewardship of Gondor',
      },
      {
        'rank': 35,
        'name': 'Haldir',
        'experience': 600,
        'department': 'Elven Guardians',
      },
      {
        'rank': 36,
        'name': 'Glorfindel',
        'experience': 600,
        'department': 'Elven Warriors',
      },
      {
        'rank': 37,
        'name': 'Beregond',
        'experience': 600,
        'department': 'Gondorian Defense',
      },
      {
        'rank': 38,
        'name': 'Imrahil',
        'experience': 600,
        'department': 'Gondorian Nobility',
      },
      {
        'rank': 39,
        'name': 'The Balrog',
        'experience': 600,
        'department': 'Mordor Forces',
      },
      {
        'rank': 40,
        'name': 'Saruman\'s Uruk-hai',
        'experience': 600,
        'department': 'Mordor Elite Forces',
      },
    ],
  },
};
