import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import './components/user-achievements/user-achievements.js';
import './components/user-points/user-points.js';
import './components/leaderboard-page/leaderboard-page.js';

import '../../users/profile-image/profile-image.js';
import './components/user-connections/user-connections.js';

import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';

import { bodyCompactStyles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

export default class UserProfile extends LocalizeNova(SkeletonMixin(RequesterMixin(LitElement))) {
  static get properties() {
    return {
      _user: { type: Object, attribute: false },
      _passedApplicationsCount: { type: Number, attribute: false },
      _skillsAndTitlesCount: { type: Number, attribute: false },
    };
  }

  static get styles() {
    return [super.styles, heading2Styles, heading3Styles, bodyCompactStyles, css`
      .profile-page {
        display: flex;
        flex-direction: row;
      }

      .profile-page .profile-side {
        background: white;
        border: 1px solid var(--d2l-color-mica);
        border-radius: 6px;
        flex: 1;
        margin-right: 20px;
        min-height: 600px;
        padding: 12px;
      }

      .profile-card {
        align-items: center;
        border-bottom: 1px solid var(--d2l-color-mica);
        display: flex;
        flex-direction: row;
        justify-content: start;
        padding-bottom: 12px;
      }

      .profile-card .profile-info {
        margin-left: 10px;
      }

      .profile-card .profile-info h2 {
        margin: 0;
      }

      .profile-card .profile-info p {
        margin: 0;
      }

      .profile-page .profile-main {
        flex: 3;
      }

      .stat-card-text {
        margin: 0;
      }
    `];
  }

  constructor() {
    super();
    this._user = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    super.firstUpdated();
    this.skeleton = true;
    this._user = this.session._user;

    this._user.achievements = [
      {
        id: 1,
        achievementName: 'Networking Novice',
        achievementCategory: 'Connections',
        achievementDescription: 'Made 1 Connection',
        isEarned: true,
        dateEarned: '2024-10-05T15:30:43Z',
        points: 10,
        earnedIcon: '../../../../../../assets/img/Achievements/earned-1.svg',
        unearnedIcon: '../../../../../../assets/img/Achievements/unearned-1.svg',
      },
      {
        id: 4,
        achievementName: 'Course Mastery',
        achievementCategory: 'Milestones',
        achievementDescription: 'Finish 5 Courses',
        isEarned: true,
        dateEarned: '2024-10-05T15:30:43Z',
        points: 5,
        earnedIcon: '../../../../../../assets/img/Achievements/earned-3.svg',
        unearnedIcon: '../../../../../../assets/img/Achievements/unearned-3.svg',
      },
    ];

    this.requestUpdate();

    const applications = await this.client.getApplications(this._user.guid);
    this._passedApplicationsCount = applications.filter(app => app.completionStatus === 'Pass').length;
    this.skills = await this.client.getSkillProfile(this.session.tenantId, this._user.guid);
    this._skillsAndTitlesCount = this.skills?.skillCategories?.length + this.skills?.titles?.length;
    this.skeleton = false;
  }

  render() {
    return html`
      <div class="profile-page">
        <div class="profile-side">
          <div class="profile-card">
            <profile-image .user=${this._user} hide-profile-text circle></profile-image>
            <div class="profile-info">
              <h2 class="d2l-heading-3">${this._user?.firstName} ${this._user?.lastName}</h2>
              <p class="d2l-body-compact">${this._user?.department}</p>
              <p class="d2l-body-compact">${this._user?.title}</p>
            </div>
          </div>
          <user-points .user="${this._user}"></user-points>
          <d2l-list>
            <d2l-list-item label="${this.localize('user-profile.stats-card.coursesCompleted')}" no-primary-action>
              <img slot="illustration" src="../../../../../../assets/img/courses-icon.png" alt="Course Icon"/>
              <d2l-list-item-content>
                <div>${this.localize('user-profile.stats-card.coursesCompleted')}</div>
                <div slot="supporting-info" class="stat-card-text d2l-heading-2 d2l-skeletize">${this._passedApplicationsCount}</div>
              </d2l-list-item-content>
            </d2l-list-item>
            <d2l-list-item label="${this.localize('user-profile.stats-card.skillsExplored')}" no-primary-action>
              <img slot="illustration" src="./../../../../../assets/img/skills-icon.png" alt="Course Icon"/>
              <d2l-list-item-content>
                <div>${this.localize('user-profile.stats-card.skillsExplored')}</div>
                <div slot="supporting-info" class="stat-card-text d2l-heading-2 d2l-skeletize">${this._skillsAndTitlesCount}</div>
              </d2l-list-item-content>
            </d2l-list-item>
            <d2l-list-item label="${this.localize('user-profile.stats-card.connectionsMade')}" no-primary-action>
              <img slot="illustration" src="./../../../../../assets/img/connections-icon.png" alt="Course Icon"/>
              <d2l-list-item-content>
                <div>${this.localize('user-profile.stats-card.connectionsMade')}</div>
                <div slot="supporting-info"><d2l-status-indicator state="default" text="Coming Soon!"></d2l-status-indicator>
                </div>
              </d2l-list-item-content>
            </d2l-list-item>
            <d2l-list-item label="${this.localize('user-profile.stats-card.achievementsUnlocked')}" no-primary-action>
              <img slot="illustration" src="../../../../../../assets/img/achievements-icon.png" alt="Course Icon"/>
              <d2l-list-item-content>
                <div>${this.localize('user-profile.stats-card.achievementsUnlocked')}</div>
                <div slot="supporting-info" class="stat-card-text d2l-heading-2 d2l-skeletize">${this._user?.achievements?.length}</div>
              </d2l-list-item-content>
            </d2l-list-item>
          </d2l-list>
        </div>
        <div class="profile-main">
          <d2l-tabs>
            <d2l-tab-panel selected text="${this.localize('user-profile.myLearningTab')}" >
              <div class="achievements-column">
                <user-achievements .user="${this._user}"></user-achievements>
              </div>
            </d2l-tab-panel>
            <d2l-tab-panel text="${this.localize('user-profile.myFeedTab')}">
            </d2l-tab-panel>
            <d2l-tab-panel text="Leaderboard">
               <leaderboard-page .user="${this._user}"></leaderboard-page>
            <d2l-tab-panel text="Connections">
              <user-connections></user-connections>
            </d2l-tab-panel>
          </d2l-tabs>
        </div>
      </div>
    `;
  }
}

window.customElements.define('user-profile', UserProfile);

