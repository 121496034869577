/* eslint-disable lit/no-template-arrow */
import '@brightspace-ui/core/components/button/button.js';
import { css, html, LitElement } from 'lit';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { testData as data } from '../leaderboard-page/testData.js';
import { LocalizeNova } from '../../../../../mixins/localize-nova/localize-nova.js';

class RankLeaderboard extends LocalizeNova(SkeletonMixin(LitElement)) {

  static get properties() {
    return {
      user: { type: Object, attribute: false },
      rankings : { type: Array, attribute: false },
      leaderboardMetadata: { type: Object, attribute: false },
      rankLeaderboard: { type: String },
    };
  }

  get tableHeaders() {
    return ['Rank', 'Department', 'Name', 'Experience'];
  }

  get batchSize() {
    return 5;
  }

  static get styles() {
    return [tableStyles, css`
      .load-more-button-container {
        border: var(--d2l-table-border);
        text-align: center;
      }

      .table-wrapper {
        height: 100%;
        max-height: 500px;
        overflow-y: scroll;
      }

      .rank-heading {
        margin-bottom: 0.25rem;
        margin-top: 3rem;
        text-align: center;
      }

      .rank-level-range {
        margin-bottom: 0.25rem;
        margin-top: 0;
        text-align: center;
      }

      #user-row {
        background-color: var(--d2l-color-celestine-plus-2) !important;
      }

      .rank-leaderboard {
        td, th {
          text-align: center;
        }
      }

      .load-more-button {
        width: 100%;
      }

      .rank-leaderboard tbody td, .load-more-button-container {
        border: none;
      }
    `];
  }

  constructor() {
    super();
    this.user = null;
    this.rankings = [];
    this.leaderboardMetadata = null;
  }

  _loadMore(startRank, endRank, addingToStart) {
    if (startRank < 1) startRank = 1;
    if (endRank > this.leaderboardMetadata.rankSize) endRank = this.leaderboardMetadata.rankSize;
    // endRank doesn't get a -1 because slice isn't end inclusive, but startRank gets - 1 to convert from rank to index
    if (addingToStart) {
      // push new rankings to the front
      this.rankings = [...data[this.rankLeaderboard].rankings.slice(startRank - 1, endRank), ...this.rankings];
    } else {
      this.rankings = [...this.rankings, ...data[this.rankLeaderboard].rankings.slice(startRank - 1, endRank)];
    }
  }

  _getInitialRankings() {
    const lastPosition = this.leaderboardMetadata.rankSize;
    // so we want to display 10 and if the diff is less than 10 we'll start with those
    const maxToDisplay = 5;

    let start = 0;
    let end = 0;

    if (lastPosition <= maxToDisplay) {
      end = maxToDisplay;
    } else {
      // we want to find the middle
      const middle = Math.floor(lastPosition / 2);
      start = middle - Math.floor(maxToDisplay / 2);
      end = middle + Math.floor(maxToDisplay / 2) + (maxToDisplay % 2);
    }
    return data[this.rankLeaderboard].rankings.slice(start, end);
  }

  firstUpdated() {
    this.leaderboardMetadata = data[this.rankLeaderboard]?.metadata;
    this.rankings = this._getInitialRankings();

    // set styles on the pesky shadowroot buttons
    setTimeout(() => { /* code for checking DOM styles */
      const loadMoreButtons = this.shadowRoot.querySelectorAll('.load-more-button');
      loadMoreButtons.forEach(button => {
        const buttonElement = button?.shadowRoot?.firstElementChild;
        if (buttonElement) buttonElement.style.width = '100%';
      });

      // bubble up a reference to the user row
      if (this.rankLeaderboard === 'Navigator') {
        this.dispatchEvent(new CustomEvent('user-row-loaded', {
          detail: this.shadowRoot.querySelector('#user-row'),
          bubbles: true,
          composed: true,
        }));
      }
    }, 10);
  }

  _loadMoreRowsButton(loadHigherRanks) {
    if (!loadHigherRanks && this.rankings[0]?.rank > 1) {
      // get our range of ranks to add
      const startRank = (this.rankings[0]?.rank - (this.batchSize));
      const endRank = (this.rankings[0]?.rank - 1);
      return html`
        <tr>
          <td class="load-more-button-container" colspan="${this.tableHeaders.length}">
            <d2l-button-subtle 
              primary=true 
              id="load-higher-ranks-button"
              class="load-more-button"
              icon="tier1:arrow-toggle-up" 
              @click=${() => {this._loadMore(startRank, endRank, true);}} 
              text="Load Higher Ranks"
              >
            </d2l-button-subtle>
          </td>
        </tr>
      `;
    } else if (loadHigherRanks && this.rankings[this.rankings.length - 1]?.rank !== this.leaderboardMetadata?.rankSize) {
      const startRank = this.rankings[this.rankings.length - 1]?.rank + 1;
      const endRank = this.rankings[this.rankings.length - 1]?.rank + (this.batchSize);
      return html`
        <tr>
          <td class="load-more-button-container" colspan="${this.tableHeaders.length}">
            <d2l-button-subtle 
              primary=true 
              id="load-lower-ranks-button"
              class="load-more-button"
              icon="tier1:arrow-toggle-down" 
              @click=${() => {this._loadMore(startRank, endRank, false);}} 
              text="Load Lower Ranks">
            </d2l-button-subtle>
          </td>
        </tr>
      `;
    }
  }

  render() {
    return html`
      <div>
        <h2 class="rank-heading">*** ${this.leaderboardMetadata?.rankTitle} ***</h2>
        <p class="rank-level-range">${this.leaderboardMetadata?.experienceBottom}xp - ${this.leaderboardMetadata?.experienceTop}xp</p>
        <d2l-table-wrapper type="light" class="table-wrapper" sticky-headers=true>
          <table class="d2l-table rank-leaderboard">
            <thead>
              <tr>
                ${this.tableHeaders.map(header => html`<th>${header}</th>`)}
              </tr>
            </thead>
            ${this._loadMoreRowsButton(false)}
            <tbody>
              ${this.rankings.map(user => html`
                <tr id="${user.name === 'Witch-king of Angmar' ? 'user-row' : ''}">
                  ${this.tableHeaders.map(header => html`<td>${user[header.toLowerCase()]}</td>`)}
                </tr>
              `)}
            </tbody>
            ${this._loadMoreRowsButton(true)}
          </table>
        </d2l-table-wrapper>
      </div>
    `;
  }
}

window.customElements.define('rank-leaderboard', RankLeaderboard);
