import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';
import '@brightspace-ui/core/components/button/button-icon.js';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../../../mixins/localize-nova/localize-nova.js';

class UserConnections extends LocalizeNova(SkeletonMixin(RequesterMixin(LitElement))) {
  static get properties() {
    return {
      _connections: { type: Array },
    };
  }

  static get styles() {
    return css`
      .connections-tab {
        display: flex;
        flex-direction: column;
        gap: 18px;
      }

      .send-connection-requests {
        align-items: end;
        display: flex;
        flex-direction: row;
        gap: 18px;
      }

      .email-input {
        max-width: 300px;
      }
    `;
  }

  constructor() {
    super();

    this._connections = [];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');

    await this._fetchConnections();
  }

  async _fetchConnections() {
    this._connections = await this.client.getConnectionRequest(this.session._user.userId);
  }

  async _sendRequest() {
    const email = this.shadowRoot.querySelector('#request-email-input')?.value;
    await this.client.sendConnectionRequest(this.session._user.guid, email);

    await this._fetchConnections();
  }

  async _acceptRequest(e) {
    const connectionId = e.target.getAttribute('request-id');

    await this.client.acceptConnectionRequest(connectionId);

    await this._fetchConnections();
  }

  _renderConnectionRequests() {
    const connectionRequests = this._connections.filter(connection => connection.status === 'Requested');

    return html`
      <d2l-list>
        ${connectionRequests.map(connection => html`
          <d2l-list-item selectable label="${connection.connectionUserId}">
            <d2l-list-item-content>
              <div>${connection.connectionUserId}</div>
            </d2l-list-item-content>
            <div slot="actions">
              <d2l-button-icon text="Accept" icon="tier1:check" @click=${this._acceptRequest} request-id="${connection.id}"></d2l-button-icon>
            </div>
          </d2l-list-item>  
        `)}
      </d2l-list>
    `;
  }

  _renderConnections() {
    const connections = this._connections.filter(connection => connection.status === 'Accepted');

    return html`
      <d2l-list>
        ${connections.map(connection => html`
          <d2l-list-item selectable label="${connection.connectionUserId}">
            <d2l-list-item-content>
              <div>${connection.connectionUserId}</div>
            </d2l-list-item-content>
          </d2l-list-item>  
        `)}
      </d2l-list>
    `;
  }

  render() {
    return html`
      <div class="connections-tab">
        <h2>My Connections</h2>
        <h3>Send Connection requests</h3>
        <div class="send-connection-requests">
          <d2l-input-text id="request-email-input" class="email-input" label="User Email"></d2l-input-text>
          <d2l-button primary @click=${this._sendRequest}>Send Request</d2l-button>
        </div>
        <h3>Connection Requests</h3>
        ${this._renderConnectionRequests()}
        <h3>Connections</h3>
        ${this._renderConnections()}
      </div>
    `;
  }
}

window.customElements.define('user-connections', UserConnections);

