import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';

import { css, html, LitElement } from 'lit';

import { heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

import '../achievements-card/achievements-card.js';

export default class AchievementsTable extends LocalizeNova(nav(LitElement)) {
  static get properties() {
    // add properties here
    return {
      achievements: { type: Array, attribute: false },
    };
  }

  static get styles() {
    // add styles here
    return [
      heading2Styles,
      css`
      section {
        background-color: #f9fbff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0 1.5rem 0;
        padding: 0 0 1.5rem 0;
      }

      .achievement-category {
        background-color: #d2e5ff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        font-weight: bold;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-top: 1rem;
      }

      .achievements-card-wrapper {
        align-items: flex-start;
        display: flex;
        padding-bottom: 1rem;
        padding-top: 1rem;
      }

      @media (max-width: 676px) {
        .achievements-card-wrapper {
          flex-direction: column;
        }
      }
    `,
    ];
  }

  constructor() {
    super();
    // add constructor code here
    this.achievements = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadMockData();
  }

  // add mock achievements data here
  async loadMockData() {
    // Simulate a fetch with a timeout
    this.achievements = await new Promise(resolve => {
      setTimeout(() => {
        resolve([
          {
            id: 1,
            achievementName: 'Networking Novice',
            achievementCategory: 'Connections',
            achievementDescription: 'Made 1 Connection',
            isEarned: true,
            dateEarned: '2024-10-05T15:30:43Z',
            points: 10,
            earnedIcon: '../../../../../../assets/img/Achievements/earned-1.svg',
            unearnedIcon: '../../../../../../assets/img/Achievements/unearned-1.svg',
          },
          {
            id: 2,
            achievementName: 'Connection Builder',
            achievementCategory: 'Connections',
            achievementDescription: 'Made 5 Connections',
            isEarned: false,
            points: 20,
            earnedIcon: '../../../../../../assets/img/Achievements/earned-2.svg',
            unearnedIcon: '../../../../../../assets/img/Achievements/unearned-2.svg',
          },
          {
            id: 3,
            achievementName: 'Request Sent',
            achievementCategory: 'Introduction',
            achievementDescription: 'Send a Request',
            isEarned: false,
            points: 5,
            earnedIcon: '../../../../../../assets/img/Achievements/earned-1.svg',
            unearnedIcon: '../../../../../../assets/img/Achievements/unearned-1.svg',
          },
          {
            id: 4,
            achievementName: 'Course Mastery',
            achievementCategory: 'Milestones',
            achievementDescription: 'Finish 5 Courses',
            isEarned: true,
            dateEarned: '2024-10-05T15:30:43Z',
            points: 5,
            earnedIcon: '../../../../../../assets/img/Achievements/earned-3.svg',
            unearnedIcon: '../../../../../../assets/img/Achievements/unearned-3.svg',
          },
        ]);
      }, 1000); // 1-second delay
    });
  }

  // Method to group achievements by category
  _getAchievementsByCategory() {
    return this.achievements.reduce((acc, achievement) => {
      const { achievementCategory } = achievement;
      if (!acc[achievementCategory]) {
        acc[achievementCategory] = [];
      }
      acc[achievementCategory].push(achievement);
      return acc;
    }, {});
  }

  render() {
    const achievementsByCategory = this._getAchievementsByCategory();
    return html`
    <h1>Your Achievements</h1>
      <div>
        ${Object.keys(achievementsByCategory).map(category => html`
          <section>
            <h2 class="achievement-category d2l-heading-2">${category}</h2>
            <div class="achievements-card-wrapper">
              ${repeat(achievementsByCategory[category], achievement => achievement.id, achievement => html`
                  <achievements-card
                    .achievement=${achievement}
                  ></achievements-card>
              `)}
            </div>
          </section>
        `)}
      </div>
    `;
  }
}

customElements.define('achievements-table', AchievementsTable);
