import { css, html, LitElement } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class AchievementsCard extends LocalizeNova(LitElement) {
  static get properties() {
    return {
      achievement: { type : Object },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      css`
    .card-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 1rem;
      text-align: center;
    }

    .card {
      border: 1px solid #dddddd;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      height: 150px;
      padding: 1rem;
      position: relative;
      width: 150px;
    }

    .earned {
      background-color: #7ecaff;
      border: 1px solid #7ecaff;
    }

    .unearned {
      background-color: #f1f1f1;
      border: 1px solid #f1f1f1;
    }

    .earned-points-label {
      background-color: #29a7ff;
      border-radius: 0 0 8px 8px;
      bottom: 0;
      font-weight: bold;
      left: 0;
      padding: 5px 0;
      position: absolute;
      width: 100%;
    }

    .unearned-points-label {
      background-color: #787878;
      border-radius: 0 0 8px 8px;
      bottom: 0;
      font-weight: bold;
      left: 0;
      padding: 5px 0;
      position: absolute;
      width: 100%;
    }

    .earned-points-label::before {
      background: transparent;
      border-bottom-left-radius: 25%;
      box-shadow: 0 20px 0 0 #29a7ff;
      content: "";
      height: 40px;
      left: 0;
      position: absolute;
      top: -40px;
      width: 40px;
    }

    .earned-points-label::after {
      background: transparent;
      border-bottom-right-radius: 25%;
      box-shadow: 0 20px 0 0 #29a7ff;
      content: "";
      height: 40px;
      position: absolute;
      right: 0;
      top: -40px;
      width: 40px;
    }

    .unearned-points-label::before {
      background: transparent;
      border-bottom-left-radius: 25%;
      box-shadow: 0 20px 0 0 #787878;
      content: "";
      height: 40px;
      left: 0;
      position: absolute;
      top: -40px;
      width: 40px;
    }

    .unearned-points-label::after {
      background: transparent;
      border-bottom-right-radius: 25%;
      box-shadow: 0 20px 0 0 #787878;
      content: "";
      height: 40px;
      position: absolute;
      right: 0;
      top: -40px;
      width: 40px;
    }

    .achievement-name {
      margin: 0.3rem 0;
    }

    .achievement-description {
      color: #494c4e;
      font-size: 0.7rem;
      font-weight: 600;
      line-height: 1rem;
      margin: 0.3rem 0;
    }

    .achievement-date {
      color: #494c4e;
      font-size: 0.7rem;
      font-weight: 400;
      line-height: 1rem;
      margin: 0.3rem 0;
    }

    .badge-image {
      height: 100px;
      width: 100px;
    }
    `];
  }

  constructor() {
    super();
    this.achievement = {};
  }

  get formattedDateEarned() {
    return this.achievement.dateEarned ? new Date(this.achievement.dateEarned).toISOString().split('T')[0] : '';
  }

  render() {
    const { achievementName, achievementDescription, points, earnedIcon, unearnedIcon, isEarned } = this.achievement;
    return html`
      <div class="card-wrapper">
        <div class="card ${isEarned ? 'earned' : 'unearned'}">
          <img class="badge-image" src="${isEarned ? earnedIcon : unearnedIcon}" alt="Achievement Icon">
          <div class="${isEarned ? 'earned-points-label' : 'unearned-points-label'}">${points} XP</div>
        </div>
        <p class="achievement-name">${achievementName}</p>
        <p class="achievement-description">${achievementDescription}</p>
        <p class="achievement-date">${this.formattedDateEarned}</p>
      </div>
    `;
  }
}

customElements.define('achievements-card', AchievementsCard);
